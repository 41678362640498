import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    widgetType: String
  }

  WIDGET_TYPE_TO_ID_MAP = {
    "simple-mtg":    "nASREy9S",
    "purchase":      "wWsgUPR3",
    "closing-cost":  "nc6yNRBN",
    "land-transfer": "nc6yNRBN",
    "rates":         "8qrzMp2d",
    "rates-alt":     "m6UhUfxc"
  }

  connect() {
    this.widgetId = this.WIDGET_TYPE_TO_ID_MAP[this.widgetTypeValue];
    this.setupElementForPerch();
  }

  setupElementForPerch() {
    this.element.setAttribute("class", "perch-widget");
    this.element.setAttribute("widget-id", this.widgetId);

    const loaderTag = document.createElement('div');
    loaderTag.setAttribute("class", "perch-widget-loading-indicator")
    loaderTag.setAttribute("style", "text-align: center");
    loaderTag.innerText = "Loading...";
    this.element.append(loaderTag);

    const scriptTag = document.createElement('script');
    scriptTag.src = "https://embeds.myperch.io/assets/perch-embed-widget.js";
    scriptTag.onload = this.initializeWidget.bind(this);
    this.element.append(scriptTag);
  }
  initializeWidget() {
    const event = new CustomEvent(
        "perch-widget:init",
        {
          detail: { perchWidgetId: this.widgetId }
        }
    );

    window.dispatchEvent(event);
  }
}